<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="5">
                <h2 class="txt_red text-uppercase pl-3">Nota de egreso</h2>      
            </b-col>
            <b-col cols="4">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>

        <b-container class="py-4">
            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Nombre</b-th>
                                <b-td colspan="3" class="border_table">
                                    <b-form-input list="input-list" v-model="datos.nombre" class="border-0" size="sm" placeholder="Ingresa nombre del paciente" />
                                    <b-form-datalist id="input-list" :options="nombres"></b-form-datalist>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Fecha</b-th>
                                <b-td colspan="3" class="border_table">
                                    <b-form-input v-model="datos.fecha" class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hora</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="datos.hora" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <small class="txt_gray">horas</small>
                                        </span>
                                    </div>
                                </b-td>
                                <b-th class="th_style">Habitación</b-th>
                                <b-td class="border_table">
                                    <b-form-input class="border-0" size="sm" placeholder="# de habitación" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Motivo de egreso</b-th>
                                <b-td colspan="3" class="border_table">
                                    <b-form-select v-model="datos.motivo_egreso_id" :options="motivos_egresos"  class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <ul class="mt-3">
                <li>Se requiere nombre y firma del médico</li>
            </ul>
            <div class="d-flex justify-content-end mb-4">
                <b-col cols="5" sm="12" md="5" lg="5">
                    <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="getNumber">GUARDAR</button>
                </b-col>
            </div> 
        </b-container>
    </b-container>    
</template>

<script>
const urlp = 'https://siplan.com.mx/api/pacientes'
import Foto from "@/components/Paciente/Foto.vue"
const link = `https://siplan.com.mx/api/datos_medicos/datos/`;

export default {
    name: "NotaDeEgreso",
    components: { Foto },
    data:()=>({
        datos: {
            "expediente_id": null,//Requerido 
            "fecha": "",//Requerido formato aaaa/mm/dd
            "hora": "",//Requerido formato hh:mm:ss
            "motivo_egreso_id": null//Requerido catálogo de motivo de egreso
        },
        "motivos_egresos": [
            {
                value: null,
                text: "Seleccionar"
            },
            {
                value: 1,
                text: "Mejoría"
            },
            {
                value: 2,
                text: "Defunción"
            },
            {
                value: 3,
                text: "Traslado"
            },
            {
                value: 4,
                text: "Máximo beneficio"
            },
            {
                value: 5,
                text: "Alta voluntaria"
            }
        ],
        pacientes: [{}],
        nombres: [],
        idPacienteSeleccionado: "",
    }),
    methods:{
        async obtenerPacientes() {
            try {
                const res = await fetch(`${urlp}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.pacientes=data.pacientes
                for(let val of data.pacientes){
                    this.nombres.push(val.id+" - "+val.nombre)
                }
            } catch (error) {
                console.log("el error es"+error);
            }
        },
        async getExpediente() {
            try {
                const res = await fetch(`${link}${this.idPacienteSeleccionado}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.datos.expediente_id=data.expediente.id
                this.egreso()
            } catch (error) {
                console.log("el error es"+error);
            }
        },

        getNumber() {
            var str = this.datos.nombre;
            var matches = str.match(/(\d+)/);
            this.idPacienteSeleccionado=matches[0]
            this.getExpediente();
        },
        async egreso() {
            try {
                const res = await fetch('https://siplan.com.mx/api/notas/egreso/store', {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos),
                });
                const data = res.json();
                console.log(data)
                
                var promise = Promise.resolve(data);
 
                promise.then(function(val) {
                    console.log(val);
                });
            } catch (error) {
                console.log(error);
            }
       },
    },
    mounted(){
        this.obtenerPacientes();
        var date = new Date;
        var minutes = date.getMinutes();
        var hour = date.getHours();
        this.datos.hora=""+hour+":"+minutes+":00"
        var today = new Date();
        var dd = ("0" + (today.getDate())).slice(-2);
        var mm = ("0" + (today.getMonth() +1)).slice(-2);
        var yyyy = today.getFullYear();
        this.datos.fecha = yyyy + '-' + mm + '-' + dd ;
    },
}
</script>

<style scoped>

</style>